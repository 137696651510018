<!--
@component
Here's some documentation for this component.
-->

<script lang="ts">
	// components
	import ParentIsOnScreen from "$tools/ParentIsOnScreen.svelte";

	// assets
	import { default as blankSvg } from "$assets/blank.svg";
	import { default as ringSvg } from "$assets/ring.svg";
	import { default as starSvg } from "$assets/star.svg";
	import { default as circleSvg } from "$assets/circle.svg";

	const media: { [key: string]: { src: string | null } } = {
		base: { src: blankSvg },
		ring: { src: ringSvg },
		star: { src: starSvg },
		circle: { src: circleSvg },
		photo: { src: null },
	};

	// types
	import type { BillboardImageLayer } from "$types/types";

	// refs
	export let images: BillboardImageLayer[] = [];
	// ref
	let div: HTMLDivElement;

	// variables
	let id: string;
	let index: number;
	let isShape: boolean;
	let layer: BillboardImageLayer;

	// reactive variables
	let isOnScreen: boolean;
	$: isOnScreen = false;

	// animation logic
	$: {
		if (isOnScreen && div && div.classList.contains("animation-reset")) {
			div.classList.remove("animation-reset");
		} else if (div) {
			div.classList.add("animation-reset");
		}
	}
</script>

<template lang="pug">
svelte:options(immutable=true)

#billboard-images.hidden.relative.select-none.overflow-visible.animation-reset(
		aria-hidden="true"
		bind:this!="{div}"
		class!="flex justify-center w-full md:max-w-lg sm:block lg:items-center lg:order-2",
		style="transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); transform-style: preserve-3d; backface-visibility: hidden;"
		)
		ParentIsOnScreen(
		on:isOnScreenTestResults!="{(e) => {isOnScreen = e.detail}}",
		tolerance!="{ 0.25 }"
	)

		//- all
		+each('images as layer, index')

			img.layer(
				alt!="{layer.description ?? ''}",
				class!="{layer.classes ?? ''}",
				draggable="false",
				height!="{layer.height ?? null}",
				id!="billboard-image-{layer.id}",
				loading="lazy",
				width!="{layer.width ?? null}",
				src!="{media[layer.type].src ?? layer.src ?? ''}"
				style!="{layer.style}"
				)

</template>

<style>
	#billboard-images.animation-reset .layer {
		transform: translate(0, 0) scale(0.1) skew(0) rotate(0) !important;
	}
	#billboard-images.animation-reset .layer.circle {
		transform: translate(0, 0) scale(1) skew(0) rotate(0) !important;
	}
	#billboard-images.animation-reset .layer.star {
		transform: translate(0, 0) scale(0.55) skew(0) rotate(0) !important;
	}
</style>
