<!-- @component
    Multi-purpose card component. Used to display portfolio companies on the front page, news and portfolio sections.
 -->

<script lang="ts">
	// types
	import type { PortfolioCard } from "$types/types";

	// props
	export let card: PortfolioCard;
	export let index: number;
</script>

<template lang="pug">
	svelte:options(immutable=true)
	a.group.rounded-xl(
		arial-labelledby!="portfolio-card-{index}"
		href!="{card.cardLink.href}"
		rel!="{card.cardLink.rel}"
		title!="{card.cardLink.title}"
		class!="{card.image ? 'bg-mintCream' : 'grid grid-cols-1 place-content-end bg-[#639B1C] text-ghostWhite'}"
		class!=`
			block
			cursor-pointer
			outline-white
			outline-offset-8
			overflow-hidden
			pb-10
			relative
			transition-shadow
			shadow-night/10
			shadow-xl
			hover:shadow-3xl
			hover:shadow-night/30`,

	)
		//- image
		+if('card.image')
			img.w-full.h-auto.block.select-none(
				alt!="{ card.image.alt }",
				aria-hidden="true",
				draggable="false",
				height!="{ card.image.height }",
				loading="lazy"
				src!="{ card.image.src }",
				width!="{ card.image.width }"
			)

		//- logo
		+if('card.logo')
			img.absolute.top-0.left-0.block.w-auto.select-none(
				alt!="{ card.logo.alt }",
				aria-hidden="true",
				class="h-[3.1em] rounded-br-md bg-ghostWhite transition-opacity"
				draggable="false",
				height!="{ card.logo.height }",
				loading="lazy",
				src!="{ card.logo.src }",
				width!="{ card.logo.width }"
				)

		//- heading
		h3.pt-5(
			class=`
			font-medium
			leading-snug
			mb-2
			opacity-90
			px-6
			text-[1.05em]
			hover:opacity-100
			`
			id!="portfolio-card-{index}"
			) {card.heading}


		//- text
		p(
			class=`
			font-light
			px-6
			opacity-95
			text-[.95em]`
			) {card.text}

		+if('card.cardLink.label')
			.px-6(class!="pt-5 -mb-2")
				span(
					class!=`
						bg-transparent
						border
						border-current
						cursor-pointer
						outline-current
						opacity-80
						px-3
						py-2
						rounded-md
						shadow-sm
						shadow-night/10
						text-[0.8em]
						transition-all
						group-focus:bg-appleGreen/40
						group-focus:opacity-100
						group-focus:drop-shadow
						group-focus:text-white
						group-hover:bg-appleGreen/40
						group-hover:opacity-100
						group-hover:drop-shadow
						group-hover:text-white`,
				) { card.cardLink.label ?? '/contact' }
</template>
