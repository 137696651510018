<!-- @component
	Organism: The heading section with tout, intro graf, and an image in place of the mobile from the original
-->
<script lang="ts">
	// get context api
	import { getContext } from "svelte";

	// components
	import BillboardAnimatedImages from "$molecules/BillboardAnimatedImages.svelte";

	// types
	import type { Billboard } from "$types/types";

	// props
	export let billboard: Billboard;

	// unpack props
	const { headlines, images, mobileImage, text } = billboard;
</script>

<template lang="pug">
	svelte:options(immutable=true)
	section#billboard.select-none(
		class!=`
			border-b
			border-ghostWhite/40
			h-auto
			grid
			grid-cols-1
			overflow-visible
			page-x-padding
			pb-24
			text-neutral-50
			w-full
			sm:place-items-center
			lg:gap-x-16
			lg:grid-cols-2
			lg:py-24
			xl:py-32
			2xl:py-40
			lg:place-content-center
			`
	)
		//- billboard animated images for tablet and desktop
		BillboardAnimatedImages(
			images!="{ images }"
		)

		//- billboard static image for mobile
		img.block.composite-image.w-full.h-auto.select-none(
			alt!="{mobileImage.alt}"
			class="sm:hidden"
			aria-hidden="true"
			height!="{mobileImage.height}"
			loading="lazy"
			width!="{mobileImage.width}"
			draggable="false"
			src!="{mobileImage.src}"
			)

		//- text content container
		div.select-auto(class="lg:order-1")
			//- headline
			h1(
				class=`
					block
					capitalize
					drop-shadow-sm
					font-medium
					leading-tight
					mb-8
					text-center
					text-[1.65em]
					lg:text-left
					lg:text-[1.75em]`
			)
				+each('headlines as headline, index')
					+const('l = headlines.length')
					span( class="block {index == l - 1 ? 'text-maize' : ''}") { headline }


			//- text
			p(
				class!=`
					font-light
					opacity-[98%]
					text-center
					text-mintCream
					lg:text-left
					`
			) { text }
</template>
