<script lang="ts">
	// set context
	import { setContext } from "svelte";

	// components
	import HomePortal from "$organisms/HomePortal.svelte";
	import HomeBillboard from "$organisms/HomeBillboard.svelte";
	import HomePortfolio from "$organisms/HomePortfolio.svelte";
	import SectionPeople from "$organisms/SectionPeople.svelte";
	import SectionNews from "$organisms/SectionNews.svelte";

	// types
	import type { PageData } from "./$types";

	// data
	export let data;

	// pass data to billboard images component via context
	setContext("billboard", data.billboard);
</script>

<template lang="pug">
	main.relative.w-full(class="max-w-screen")
		//- billboard
		HomeBillboard(billboard!="{ data.billboard }")

		//- portal & benefits -- unpublished by customer request Oct 2023
		//- HomePortal
		//- HomeBenefits

		//- portfolio
		HomePortfolio(portfolio!="{ data.portfolio }")

		//- our team
		SectionPeople(
			heading="Our Team",
			id="our-team",
			people!="{ data.execs }")

		//- hr
		.pt-0(class!="page-x-padding")
			.border-b(class="border-b-green-950/40")

		//- the board
		SectionPeople(
			heading="The Board",
			id="board"
			people!="{ data.board }"
			)

		//-news
		SectionNews(news!="{ data.news }")
</template>
