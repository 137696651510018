<!-- @component
	Section of the front page that displays links to the Zenith Capital portfolio companies in the form of picture cards with logos and summaries.
 -->

<script lang="ts">
	// components
	import CardHeadline from "$atoms/CardHeadline.svelte";
	import CardNews from "$molecules/CardNews.svelte";
	import WidgetHeader from "$molecules/WidgetHeader.svelte";

	// props
	export let news: NewsArticle[];

	// types
	import type { NewsArticle } from "$types/types";

	// declare & type variables
	let article: NewsArticle;
	let articleIndex: number;
</script>

<template lang="pug">
	section#news(
		class!=`
			gap-8
			grid grid-cols-1
			page-x-padding
			pb-24
			pt-24
			w-full
			sm:grid-cols-2
			lg:grid-cols-3`
	)
		//- header
		WidgetHeader(classes=`
			!justify-start
			text-white
			w-full
			sm:col-span-2
			lg:col-span-3`)
				| News &amp; Insights

		//-  news cards
		+each('Array(3) as count, index')
			CardNews(
				headlineTag="h3"
				article!="{ news[index] }")

		//- link
		div
			a.w-auto(
				class=`
					cursor-pointer
					inline-block
					opacity-80
					outline-white
					outline-offset-8
					text-ghostWhite
					transition-all
					decoration-white/40
					underline
					underline-offset-4
					focus:opacity-100
					focus:text-maize
					focus:decoration-maize
					hover:opacity-100
					hover:text-maize
					hover:decoration-maize
					`
				href="/news"
				title="Browse all news"
			) Browse All News
</template>
