<!--
@component
Here's some documentation for this component.
-->

<script lang="ts">
	export let tag = "h3";
	export let classes = "";
</script>

<template lang="pug">
	//- heading
	svelte:element.mb-6(class!="text-[1.75em] {classes}", this!="{ tag }")
		slot
</template>
