<!-- @component
	Section of the front page that displays links to the Zenith Capital portfolio companies in the form of picture cards with logos and summaries.
 -->

<script lang="ts">
	// components
	import CardHeadline from "$atoms/CardHeadline.svelte";
	import WidgetHeader from "$molecules/WidgetHeader.svelte";

	// types
	import type { Person } from "$types/types";

	// props
	export let heading = "";
	export let id = "people";
	export let people: Person[] = [];
</script>

<template lang="pug">

	section.bg-mintCream.w-full.page-x-padding(id!="{id}" class!="people pt-24 pb-20")

		//- header
		WidgetHeader(classes="w-full sm:col-span-12 lg:mb-4 !justify-start")
				svelte:fragment { heading }

		//- cards container
		.w-full(
			class="lg:columns-2 lg:gap-16 xl:gap-18 2xl:gap-20"
		)
			//- cards
			+each('people as person')

				//- card
				.mb-8.break-inside-avoid-column.font-light(
					style!="order:{person.order}"
					)
					//- headline
					CardHeadline(classes!="mb-4", id!="{person.slug}") { person.name }
					//- bio
					+html('person.bio')
</template>

<style lang="postcss">
	.people :global(a) {
		@apply whitespace-nowrap underline underline-offset-4 decoration-appleGreen/0;
	}
	.people :global(a):hover {
		@apply decoration-primary;
	}
</style>
