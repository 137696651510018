<!-- @component
	Section of the front page that displays links to the Zenith Capital portfolio companies in the form of picture cards with logos and summaries.
 -->

<script lang="ts">
	// context api
	import { getContext } from "svelte";

	// components
	import CardPortfolio from "$molecules/CardPortfolio.svelte";
	import CardPortfolioCTA from "$molecules/CardPortfolioCTA.svelte";
	import WidgetHeader from "$molecules/WidgetHeader.svelte";

	// types
	import type { PortfolioCard } from "$types/types";

	// declaring and typing variables used in template
	let card: PortfolioCard;
	let index: number;

	// props
	export let portfolio: PortfolioCard[];
</script>

<template lang="pug">
	svelte:options(immutable=true)
	section#portfolio.relative.w-full.page-x-padding(
		class!="grid grid-cols-1 gap-y-2 py-24"
	)
		//- header
		.w-full(class="text-white")
			WidgetHeader
				| Our Portfolio

		//- content container
		.w-full(class=`
			gap-y-8
			grid
			grid-cols-1
			place-content-start
			md:gap-6
			md:grid-cols-2
			lg:grid-cols-3
			lg:gap-8`)

			+each('portfolio as card, index')

				CardPortfolio(
						card!="{ card }"
						index!="{ index }"
					)

</template>
