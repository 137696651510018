<!--
@component
Here's some documentation for this component.
-->

<script lang="ts">
	// import assets
	import star from "$assets/star.svg";

	// components
	import WidgetHeading from "$atoms/WidgetHeading.svelte";

	// props
	export let classes = "";
</script>

<template lang="pug">
	.flex.justify-center.relative(class!="{ classes }")
		WidgetHeading(
			classes=`
				drop-shadow-sm
				text-current
				whitespace-nowrap`
			tag!="h2"
			)
			slot

		//- star mark
		img.block.h-auto.select-none(
				alt="",
				aria-hidden="true",
				class=`
					drop-shadow-sm
					translate-y-[-28px]
					w-16`,
				draggable="false",
				height=348,
				loading="lazy",
				src!="{ star }",
				width=400
			)
</template>
